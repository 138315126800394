<template>
  <div class="container">
    <el-form ref="formData" class="form" :model="formData" :rules="rules" :inline="true">
      <el-form-item label="字典类型">
        <el-input v-model="dictName" disabled></el-input>
      </el-form-item>
      <el-form-item
        label="字典数据"
        prop="dictLabel"
        :rules="{
          required: true,
          trigger: 'blur',
          message: '字典数据不能为空'
        }"
      >
        <el-input
          v-model="formData.dictLabel"
          clearable
          placeholder="请输入字典数据"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="字典键值"
        prop="dictValue"
        :rules="{
          required: true,
          trigger: 'blur',
          message: '字典键值不能为空'
        }"
      >
        <el-input
          v-model="formData.dictValue"
          clearable
          placeholder="请输入字典键值"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="字典code"
        prop="dictValue"
        :rules="{
          required: true,
          trigger: 'blur',
          message: '字典code不能为空'
        }"
      >
        <el-input
          v-model="formData.dictCode"
          clearable
          placeholder="请输入字典code"
          maxlength="50"
          show-word-limit
          :disabled="isDisabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status" :rules="{ required: true, trigger: 'blur', message: '请选择状态' }">
        <el-radio-group v-model="formData.status">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="hh_btn">
      <el-button class="btn btn_blue" @click="submit('formData')">提交</el-button>
      <el-button class="btn btn_normal" @click="cancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import API from '@apis/backStageEndAPI/dictionaryManagementAPI/index';
export default {
  name: 'addDictionary',
  props: ['dictName', 'dictType', 'dictionaryList','dictId','flag'],
  data() {
    return {
      formData: {
        dictCode: '',
        dictLabel: '',
        dictValue: '',
        status: 1,
        dictType: '',
      },
      isDisabled: false,
      rules: {}
    };
  },
  mounted() {
    if (this.dictionaryList) {
      this.getList();
    }
    if(this.flag == 'edit') {
      this.isDisabled = true
    }
  },
  methods: {
    getList() {
      this.formData.dictLabel = this.dictionaryList.dictLabel;
      this.formData.dictValue = this.dictionaryList.dictValue;
      this.formData.status = Number(this.dictionaryList.status);
      this.formData.dictCode = this.dictionaryList.dictCode;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.dictType = this.dictType;
          if (this.dictionaryList) {
            API.editDictionaryData(this.formData).then((res) => {
              this.$emit('showBuild', false);
            });
          } else {
            API.addDictionaryData(this.formData).then((res) => {
              this.$emit('showBuild', false);
            });
          }
        }
      });
    },
    cancel() {
      this.$emit('showBuild', false);
    }
  }
};
</script>
<style lang="scss" scoped>
.hh_btn {
  text-align: right;
}
</style>
