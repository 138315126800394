<template>
  <div class="container">
    <header-bar></header-bar>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/dictionaryManagement' }">
        <i class="el-icon-back"></i>
        返回字典管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ this.$route.query.dictName }}字典数据</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="gl_filter bg_white">
      <el-row class="hh_btn" :gutter="24">
        <el-col>
          <el-button icon="el-icon-plus" class="btn btn_blue" @click="addDict">新建字典数据</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="字典数据" prop="dictLabel" />
        <el-table-column label="字典键值" prop="dictValue" />
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === '1'">正常</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="操作" width="380px">
          <template slot-scope="scope">
            <!-- <span class="operate" @click="toTop(scope)">上移</span>
            <span class="operate" @click="toBottom(scope)">下移</span> -->
            <span class="operate color_blue" @click="edit(scope)">编辑</span>
            <span class="operate color_red" @click="del(scope)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      class="dialog_wrapper"
      :title="title"
      v-if="isNewBuild"
      :visible.sync="isNewBuild"
      :close-on-click-modal="false"
      width="30%"
    >
      <add-dictionary-data
        :dictionaryList="dictionaryList"
        @showBuild="showBuild"
        :dictName="this.$route.query.dictName"
        :dictType="this.$route.query.dictType"
        :dictId="this.$route.query.dictId"
        :flag="flag"
      ></add-dictionary-data>
    </el-dialog>
  </div>
</template>
<script>
import headerBar from '@comps/backStageComponent/layout/headerBar/headerBar';
import Pagination from '@comps/backStageComponent/pagination';
import addDictionaryData from './components/addDictionaryData';
import API from '@apis/backStageEndAPI/dictionaryManagementAPI/index';
export default {
  name: 'dictionaryDetail',
  components: {
    headerBar,
    Pagination,
    addDictionaryData,
  },
  data() {
    return {
      dictionaryList: [],
      tableData: [],
      isNewBuild: false,
      title: '',
      flag: '',
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      API.getDicData(this.$route.query.dictType).then(res => {
        this.tableData = res;
      });
    },
    showBuild(bool) {
      this.isNewBuild = bool;
      this.getList();
    },
    toTop() {},
    toBottom() {},
    del(scope) {
      API.delDictionaryData(scope.row.dictCode).then(() => {
        this.getList();
      });
    },
    addDict() {
      this.isNewBuild = true;
      this.title = '新建字典数据';
      this.dictionaryList = '';
      this.flag = ''
    },
    edit(scope) {
      this.dictionaryList = scope.row;
      this.isNewBuild = true;
      this.title = '编辑字典数据';
      this.flag = 'edit'
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .gl_filter {
    margin: 16px 0;
    padding: 16px;
    border-radius: 12px;
  }
  .hh_btn {
    text-align: right;
  }
  .el-breadcrumb__item {
    font-size: 16px;

    ::v-deep .is-link {
      color: var(--colorBlue);

      .el-icon-back {
        margin-right: 12px;
      }
    }
  }
}
</style>
