import { render, staticRenderFns } from "./dictionaryDetail.vue?vue&type=template&id=05cb9f07&scoped=true"
import script from "./dictionaryDetail.vue?vue&type=script&lang=js"
export * from "./dictionaryDetail.vue?vue&type=script&lang=js"
import style0 from "./dictionaryDetail.vue?vue&type=style&index=0&id=05cb9f07&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cb9f07",
  null
  
)

export default component.exports