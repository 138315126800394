var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('el-form',{ref:"formData",staticClass:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"inline":true}},[_c('el-form-item',{attrs:{"label":"字典类型"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.dictName),callback:function ($$v) {_vm.dictName=$$v},expression:"dictName"}})],1),_c('el-form-item',{attrs:{"label":"字典数据","prop":"dictLabel","rules":{
        required: true,
        trigger: 'blur',
        message: '字典数据不能为空'
      }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入字典数据","maxlength":"50","show-word-limit":""},model:{value:(_vm.formData.dictLabel),callback:function ($$v) {_vm.$set(_vm.formData, "dictLabel", $$v)},expression:"formData.dictLabel"}})],1),_c('el-form-item',{attrs:{"label":"字典键值","prop":"dictValue","rules":{
        required: true,
        trigger: 'blur',
        message: '字典键值不能为空'
      }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入字典键值","maxlength":"50","show-word-limit":""},model:{value:(_vm.formData.dictValue),callback:function ($$v) {_vm.$set(_vm.formData, "dictValue", $$v)},expression:"formData.dictValue"}})],1),_c('el-form-item',{attrs:{"label":"字典code","prop":"dictValue","rules":{
        required: true,
        trigger: 'blur',
        message: '字典code不能为空'
      }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入字典code","maxlength":"50","show-word-limit":"","disabled":_vm.isDisabled},model:{value:(_vm.formData.dictCode),callback:function ($$v) {_vm.$set(_vm.formData, "dictCode", $$v)},expression:"formData.dictCode"}})],1),_c('el-form-item',{attrs:{"label":"状态","prop":"status","rules":{ required: true, trigger: 'blur', message: '请选择状态' }}},[_c('el-radio-group',{model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("正常")]),_c('el-radio',{attrs:{"label":0}},[_vm._v("停用")])],1)],1)],1),_c('div',{staticClass:"hh_btn"},[_c('el-button',{staticClass:"btn btn_blue",on:{"click":function($event){return _vm.submit('formData')}}},[_vm._v("提交")]),_c('el-button',{staticClass:"btn btn_normal",on:{"click":_vm.cancel}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }